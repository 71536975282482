import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { FIREBASE_STRUCT } from 'src/app/app.constant';
import { UserProfile } from 'src/app/layout/user-profile/user-profile.model';
import { ApiService } from 'src/app/services/api.service';
import { Handling } from 'src/app/decorators/handling';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
})
export class GuideComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  contentHtml = '';
  modalTitle = '';
  contentNode = '';
  qrs = [];
  transferSyntax = '';

  constructor(
    private modalCtrl: ModalController,
    private fs: AngularFirestore,
    private navParams: NavParams,
    private auth: AngularFireAuth,
    private apiService: ApiService

  ) {
    this.modalTitle = this.navParams.get('modalTitle');
    this.contentNode = this.navParams.get('contentNode');
  }

  @Handling()
  async ngOnInit() {
    const contentNode: string = this.navParams.get('contentNode');
    const userUid = this.auth.auth.currentUser.uid;
    const user: any = await this._getCurrentUser(userUid);
    this.transferSyntax = `CK ${user.login_name}`;

    if (contentNode === 'home_recharge_guide') {
      await this._generateQR(user);
    }

    this.getContent(contentNode).subscribe((content: any) => {
      if (content) {
        this.contentHtml = content.content_html;
      } else {
        this.contentHtml = '';
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /* public onCopyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  } */
  
  public async onCopyText(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  getContent(contentNode: string) {
    return this.fs.collection('PARAMETER_CONTENT').doc(`${contentNode}`).valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  private async _getCurrentUser(userUid: string) {
    return this.fs.collection<any>(FIREBASE_STRUCT.USERS.NODE).doc<UserProfile>(userUid).snapshotChanges().pipe(
      map(doc => ({ uid: userUid, ...doc.payload.data() })),
      first()
    ).toPromise();
  }

  private async _generateQR(user) {
    const addInfo = this.transferSyntax;
    const bankAccounts = [
        { bankName: 'SHB', acqId: '970443', accountNo: '6688668800', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FLmD6Sw7ykPZjoNRTGUAD?alt=media&token=96652ce3-de1a-448a-8b31-dc2831edc05d', note: 'Chi nhánh Quang Trung, Hà Nội' },
        { bankName: 'TPBANK', acqId: '970423', accountNo: '00002817915', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FEaTIGf7BDftScSNnvZik?alt=media&token=dd336c00-3e3f-4e42-87c2-eb3e34aa74f8', note: 'Chi nhánh Quang Trung, Hà Nội' },
        { bankName: 'Techcombank', acqId: '970407', accountNo: '19030725481788', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FCjSP1Ee8wcm3B8WrE0t2?alt=media&token=f293c0c9-02d8-4815-b61e-284b2e0bc8d6', note: 'Chi nhánh Big C, Hà Nội' },
        { bankName: 'VIB', acqId: '970441', accountNo: '040689597', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FyHKF669di7yPKfmat7BO?alt=media&token=b2f8cf49-dcd4-43a5-bdd6-6c7919ba5d37', note: 'VIB Thanh Xuân, Hà Nội' },
        { bankName: 'Vietcombank', acqId: '970436', accountNo: '0451000345152', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FinWxfqSeQQAvvaFyPITo?alt=media&token=bbee5a0f-4849-4091-90c3-5eaff5591242', note: 'Chi nhánh Thành Công, Hà Nội' },
        { bankName: 'Vietinbank', acqId: '970415', accountNo: '105879207923', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2Fj6Xt5YLKHOWC3gKvj2t0?alt=media&token=0c66862e-205f-4f0d-aca6-38aea8b22bb5', note: 'Chi nhánh Hoàng Mai, Hà Nội' },
        { bankName: 'ACB', acqId: '970416', accountNo: '34816417', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2F91sWaCqvO48f9a0Yx1QL?alt=media&token=5f0b51b9-185e-42a4-901d-926757e6a43d', note: 'Phòng giao dịch Kim Đồng, Hà Nội' },
        { bankName: 'MB Bank', acqId: '970422', accountNo: '2890197449999', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2F130t9xrCAW67isvxX1QQ?alt=media&token=8b8ca48c-f89d-4c1e-a0b8-8d54182fc592', note: 'Chi nhánh Thanh Xuân, Hà Nội' },
        { bankName: 'BIDV', acqId: '970418', accountNo: '12310001334309', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FJaAKy3MIlEen9cPIkF9O?alt=media&token=39e56f38-ac6f-4fe5-b08a-c613aea2f53c', note: 'Chi nhánh Quang Trung, Hà Nội' },
        { bankName: 'VPBank', acqId: '970432', accountNo: '2888268688', accountName: 'LE NHU NHA TRANG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2F1T2U8BbqvvQ4V1IAyf9V?alt=media&token=45753bf9-474c-4a6f-bfcf-c1d330a88cd7', note: 'Chi nhánh Hà Nội' }
      ];

    /* const httpOptions = {
      headers: new HttpHeaders({
        xClientId: '7f087dee-3f77-4d72-a987-d343c6d6edff',
        xApiKey: 'e9358faf-d8e8-4cfc-86be-49440c4c80b8'
      })
    }; */
    for (const bankAccount of bankAccounts) {
      /* const result: any = await this.apiService.postExternalURL('https://api.vietqr.io/v2/generate', { 
        accountNo: bankAccount.accountNo,
        accountName: bankAccount.accountName,
        acqId: bankAccount.acqId,
        addInfo: bankAccount.addInfo,
        format: bankAccount.format,
        template: bankAccount.template,
      }, httpOptions);

      this.qrs.push({ ...result, ...bankAccount }); */

      this.qrs.push({ ...bankAccount });
    }
  }

}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { GuideComponent } from './guide/guide.component';
import { PipesModule } from '../pipes/pipes.module';
import { ConsignmentOrderAddComponent } from './consignment-order-add/consignment-order-add/consignment-order-add.component';
import { DirectiveModule } from '../directives/directive.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        PipesModule,
        DirectiveModule
    ],
    declarations: [
        ConfirmModalComponent,
        GuideComponent,
        ConsignmentOrderAddComponent
    ],
    entryComponents: [
        ConfirmModalComponent,
        GuideComponent,
        ConsignmentOrderAddComponent
    ],
    exports: [
        ConfirmModalComponent,
        GuideComponent,
        ConsignmentOrderAddComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalsModule { }
